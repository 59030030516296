.homeWrapper {
  background-color: #282c34;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  & .timerWrapper {
    width: 30vh;
    height: 30vh;
  }

  & .buttons {
    margin: 10px;
  }
}
