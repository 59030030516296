@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Timer_timerWrapper__35Tkd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.Button_button__30zzF {
  border: none;
  cursor: pointer;
  border-radius: 8px; }
  .Button_button__30zzF:disabled {
    background-color: #b0b4b8;
    cursor: not-allowed; }

.Button_size--tiny__2q97l {
  padding: 2px;
  font-size: 0.8em; }

.Button_size--small__1NWjI {
  padding: 6px;
  font-size: 0.8em; }

.Button_size--normal__3KbSw {
  padding: 10px 16px;
  font-size: 1em; }

.Button_size--large__1wAFH {
  padding: 16px 18px;
  font-size: 1.2em; }

.Button_theme--trackButton__3VMhR {
  color: #fff;
  text-align: left; }
  .Button_theme--trackButton__3VMhR:hover {
    opacity: 0.8; }

.Button_theme--success__1wzkJ {
  background-color: #1aaf4f;
  color: #fff; }
  .Button_theme--success__1wzkJ:focus, .Button_theme--success__1wzkJ:hover:enabled {
    background-color: #13833b;
    color: #fff; }
  .Button_theme--success__1wzkJ.Button_inverse__3tjhM {
    color: #1aaf4f;
    fill: #1aaf4f;
    background-color: transparent; }
    .Button_theme--success__1wzkJ.Button_inverse__3tjhM:focus, .Button_theme--success__1wzkJ.Button_inverse__3tjhM:hover {
      color: #13833b;
      background-color: rgba(26, 175, 79, 0.5); }

.Button_theme--primary__1TwrO {
  min-width: 200px;
  background-color: #fff;
  opacity: 0.75;
  font-family: niveau-grotesk, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #092234;
  border: solid 3px #fff; }
  .Button_theme--primary__1TwrO:focus, .Button_theme--primary__1TwrO:hover:enabled {
    opacity: 1; }
  .Button_theme--primary__1TwrO.Button_inverse__3tjhM {
    opacity: 1;
    color: #fff;
    border: solid 3px #fff;
    background-color: transparent; }
    .Button_theme--primary__1TwrO.Button_inverse__3tjhM:focus, .Button_theme--primary__1TwrO.Button_inverse__3tjhM:hover {
      color: #092234;
      background-color: #fff; }
    .Button_theme--primary__1TwrO.Button_inverse__3tjhM.Button_raised__1BbIZ {
      color: #092234;
      background-color: #fff; }

.Button_theme--danger__9NkKJ {
  background-color: #bf360c;
  color: #fff; }
  .Button_theme--danger__9NkKJ:focus, .Button_theme--danger__9NkKJ:hover:enabled {
    background-color: #8f2809;
    color: #fff; }
  .Button_theme--danger__9NkKJ.Button_inverse__3tjhM {
    color: #bf360c;
    background-color: transparent; }
    .Button_theme--danger__9NkKJ.Button_inverse__3tjhM:focus, .Button_theme--danger__9NkKJ.Button_inverse__3tjhM:hover {
      color: #8f2809;
      background-color: rgba(191, 54, 12, 0.5); }

.Button_theme--warning__3rAZT {
  background-color: #ef6c00;
  color: #fff; }
  .Button_theme--warning__3rAZT:focus, .Button_theme--warning__3rAZT:hover:enabled {
    background-color: #bc5500;
    color: #fff; }
  .Button_theme--warning__3rAZT.Button_inverse__3tjhM {
    color: #bf360c;
    background-color: transparent; }
    .Button_theme--warning__3rAZT.Button_inverse__3tjhM:focus, .Button_theme--warning__3rAZT.Button_inverse__3tjhM:hover {
      color: #bc5500;
      background-color: rgba(239, 108, 0, 0.5); }

.Button_theme--light__11uqc {
  background-color: #fff;
  color: #222; }
  .Button_theme--light__11uqc:focus, .Button_theme--light__11uqc:hover {
    background-color: #e6e6e6;
    color: #222; }
  .Button_theme--light__11uqc.Button_inverse__3tjhM {
    color: #fff;
    background-color: transparent; }
    .Button_theme--light__11uqc.Button_inverse__3tjhM:focus, .Button_theme--light__11uqc.Button_inverse__3tjhM:hover {
      color: #222;
      background-color: rgba(255, 255, 255, 0.5); }

.Button_theme--dark__3BQkI {
  background-color: #222;
  color: #fff; }
  .Button_theme--dark__3BQkI:focus, .Button_theme--dark__3BQkI:hover {
    background-color: #090909;
    color: #fff; }
  .Button_theme--dark__3BQkI.Button_inverse__3tjhM {
    color: #222;
    background-color: transparent; }
    .Button_theme--dark__3BQkI.Button_inverse__3tjhM:focus, .Button_theme--dark__3BQkI.Button_inverse__3tjhM:hover {
      color: #fff;
      background-color: rgba(34, 34, 34, 0.5); }

.Button_link__29aBm {
  background-color: transparent;
  color: #2b8eaa; }
  .Button_link__29aBm:focus, .Button_link__29aBm:hover:enabled {
    background-color: #f8f9fb;
    color: #216c81; }

.Button_wide__36JRs {
  width: 100%; }

/* Split Button */
.SplitButton_buttonGroup__2EURU {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  vertical-align: middle; }
  .SplitButton_buttonGroup__2EURU.SplitButton_button__1BaPj:first-child {
    margin-left: 0; }

.SplitButton_dropdownMenu__3ep8H {
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.12rem 0 0;
  font-size: 1rem;
  color: #222;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .SplitButton_dropdownMenu__3ep8H.SplitButton_active__6T2sp {
    display: block; }

.SplitButton_dropdownItem__2EVrn {
  display: block;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #222;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
  background-color: #fff; }
  .SplitButton_dropdownItem__2EVrn:hover {
    background-color: shade(#fff, 10%); }
  .SplitButton_dropdownItema__NOggy {
    touch-action: manipulation;
    text-decoration: none; }

.SplitButton_dropdownDivider__1wd06 {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2); }

.DropButton_buttonGroup__5N6zl {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  vertical-align: middle; }
  .DropButton_buttonGroup__5N6zl.DropButton_button__1s_kW:first-child {
    margin-left: 0; }
  .DropButton_buttonGroup__5N6zl.DropButton_wide__2k-oZ {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0; }

.DropButton_dropdownMenu__3GF8P {
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.12rem 0 0;
  font-size: 1rem;
  color: #222;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .DropButton_dropdownMenu__3GF8P.DropButton_active__3p0bu {
    display: block; }

.DropButton_dropdownItem__3yMLb {
  display: block;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #222;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
  background-color: #fff; }
  .DropButton_dropdownItem__3yMLb:hover {
    background-color: shade(#fff, 10%); }
  .DropButton_dropdownItema__2PKXm {
    touch-action: manipulation;
    text-decoration: none; }

.AudioManager_audioWrapper__3S7-U {
  display: block; }

.Home_homeWrapper__1728- {
  background-color: #282c34;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }
  .Home_homeWrapper__1728- .Home_timerWrapper__1QGVK {
    width: 30vh;
    height: 30vh; }
  .Home_homeWrapper__1728- .Home_buttons__2nvmD {
    margin: 10px; }

.TimeSelect_timeSelectWrapper__1VZxF {
  flex-basis: 40%;
  flex-basis: min-content;
  background-color: #282c34;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }
  .TimeSelect_timeSelectWrapper__1VZxF.TimeSelect_isActive__2RXxh {
    display: none; }
  .TimeSelect_timeSelectWrapper__1VZxF .TimeSelect_buttons__3awZr {
    margin: 10px; }
  .TimeSelect_timeSelectWrapper__1VZxF .TimeSelect_timeOption__1Msje {
    background: rgba(255, 255, 255, 0.2);
    width: 12vw;
    height: 12vw;
    max-width: 150px;
    max-height: 150px;
    margin: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 200ms;
    transition: all 200ms; }
    .TimeSelect_timeSelectWrapper__1VZxF .TimeSelect_timeOption__1Msje:hover {
      background: rgba(255, 255, 255, 0.4); }

.Login_loginWrapper__uKcKs {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }
  .Login_loginWrapper__uKcKs .Login_buttons__lldtB {
    margin: 10px; }

.App_App__15LM- {
  text-align: center;
  background-color: #282c34; }
  .App_App__15LM- .App_pages__2WpI7 {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }

.App_App-logo__1GLNd {
  -webkit-animation: App_App-logo-spin__1EbLw infinite 20s linear;
          animation: App_App-logo-spin__1EbLw infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App_App-header__3nnPn {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App_App-link__TRj1P {
  color: #61dafb; }

@-webkit-keyframes App_App-logo-spin__1EbLw {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App_App-logo-spin__1EbLw {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

