@import '../../styles/variables.scss';

.button {
  border: none;
  cursor: pointer;
  border-radius: 8px;

  &:disabled {
    background-color: $color-gray-medium;
    cursor: not-allowed;
  }
}

.size--tiny {
  padding: 2px;
  font-size: 0.8em;
}

.size--small {
  padding: 6px;
  font-size: 0.8em;
}

.size--normal {
  padding: 10px 16px;
  font-size: 1em;
}

.size--large {
  padding: 16px 18px;
  font-size: 1.2em;
}

.theme--trackButton {
  color: $color-white;
  text-align: left;
  &:hover {
    opacity: 0.8;
  }
}

.theme--success {
  background-color: $color-green;
  color: $color-white;

  &:focus,
  &:hover:enabled {
    background-color: darken($color-green, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-green;
    fill: $color-green;
    background-color: transparent;

    &:focus,
    &:hover {
      color: darken($color-green, 10%);
      background-color: rgba($color-green, 0.5);
    }
  }
}

.theme--primary {
  min-width: 200px;
  background-color: $color-white;
  opacity: 0.75;
  font-family: niveau-grotesk, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: $color-blue-dark;
  border: solid 3px $color-white;

  &:focus,
  &:hover:enabled {
    opacity: 1;
  }
  &.inverse {
    opacity: 1;
    color: $color-white;
    border: solid 3px $color-white;
    background-color: transparent;

    &:focus,
    &:hover {
      color: $color-blue-dark;
      background-color: $color-white;
    }

    &.raised {
      color: $color-blue-dark;
      background-color: $color-white;
    }
  }
}

.theme--danger {
  background-color: $color-red;
  color: $color-white;

  &:focus,
  &:hover:enabled {
    background-color: darken($color-red, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-red;
    background-color: transparent;

    &:focus,
    &:hover {
      color: darken($color-red, 10%);
      background-color: rgba($color-red, 0.5);
    }
  }
}

.theme--warning {
  background-color: $color-orange;
  color: $color-white;

  &:focus,
  &:hover:enabled {
    background-color: darken($color-orange, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-red;
    background-color: transparent;

    &:focus,
    &:hover {
      color: darken($color-orange, 10%);
      background-color: rgba($color-orange, 0.5);
    }
  }
}

.theme--light {
  background-color: $color-white;
  color: $color-black;

  &:focus,
  &:hover {
    background-color: darken($color-white, 10%);
    color: $color-black;
  }

  &.inverse {
    color: $color-white;
    background-color: transparent;

    &:focus,
    &:hover {
      color: $color-black;
      background-color: rgba($color-white, 0.5);
    }
  }
}

.theme--dark {
  background-color: $color-black;
  color: $color-white;

  &:focus,
  &:hover {
    background-color: darken($color-black, 10%);
    color: $color-white;
  }

  &.inverse {
    color: $color-black;
    background-color: transparent;

    &:focus,
    &:hover {
      color: $color-white;
      background-color: rgba($color-black, 0.5);
    }
  }
}

.link {
  background-color: transparent;
  color: $color-blue-light;

  &:focus,
  &:hover:enabled {
    background-color: $color-gray-lighter;
    color: darken($color-blue-light, 10%);
  }
}

.wide {
  width: 100%;
}
