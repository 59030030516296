.timeSelectWrapper {
  flex-basis: 40%;
  flex-basis: min-content;
  background-color: #282c34;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  &.isActive {
    display: none;
  }

  & .buttons {
    margin: 10px;
  }

  & .timeOption {
    background: rgba(255, 255, 255, 0.2);
    width: 12vw;
    height: 12vw;
    max-width: 150px;
    max-height: 150px;
    margin: 1vw;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: all 200ms;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}
