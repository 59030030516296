@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700');

$color-black: #222;
$color-offblack: #16161d;
$color-white: #fff;
$color-offwhite: #f1f2f2;
$color-blue: #006ba4;
$color-blue-dark: #092234;
$color-blue-light: #2b8eaa;
$color-blue-bright: #00bfff;
$color-gray: #3f3f3f;
$color-gray-medium: #b0b4b8;
$color-gray-light: #e1e1e1;
$color-gray-lighter: #f8f9fb;
$color-red: #bf360c;
$color-orange: #ef6c00;
$color-yellow: #f4c20d;
$color-green: #1aaf4f;

$color-dark-divider: rgba(0, 0, 0, 0.2);
